// tslint:disable: object-literal-sort-keys

import '../stylesheets/cookieconsent.scss';
import type { CookieConsent, CookieConsentConfig } from '../vendor/cookieconsent/cookieconsent';
import '../vendor/cookieconsent/cookieconsent.css';
// NOTE: this import has a side-effect - it places an init function on the window.
import '../vendor/cookieconsent/cookieconsent.js';

interface IWindowCookieData {
  translations: {
    accept_all: string;
    close: string;
    cookie_preferences: string;
    cookies: string;
    description: string;
    domain: string;
    expiration: string;
    more_information: string;
    name: string;
    reject_all: string;
    save_settings: string;
    settings: string;
    strictly_necessary_cookies: string;
    these_cookies_are_essential: string;
    as_soon_as_browser_window_is_closed: string;
    allows_us_to_associate_file_uploads: string;
    allows_our_load_balancer: string;
    saves_your_decisions: string;
    analytics_and_performance_cookies: string;
    these_cookies_remember_the_choices: string;
    internal_metrics_collector: string;
    six_months: string;
    two_years: string;
    four_hours: string;
  };
  customContent: {
    notice: string;
    explanation: string;
    further_information?: string;
  };
}

interface IWindowWithCookieData extends Window {
  cookieConsentData?: IWindowCookieData;
  cookieConsentCustomConfig?: CookieConsentConfig;
  initCookieConsent(): CookieConsent;
}

function createNewCookieConsent() {
  const { cookieConsentData } = window as unknown as IWindowWithCookieData;
  const { cookieConsentCustomConfig } = window as unknown as IWindowWithCookieData;

  const cookieConsent = (window as unknown as IWindowWithCookieData).initCookieConsent();

  if (cookieConsentCustomConfig) {
    cookieConsent.run(cookieConsentCustomConfig);
  } else {
    const { translations, customContent } = cookieConsentData;

    const blocks: CookieConsentConfig['languages'][string]['settings_modal']['blocks'] = [
      {
        description: customContent.explanation,
      },
    ];

    blocks.push({
      title: translations.strictly_necessary_cookies,
      description: translations.these_cookies_are_essential,
      toggle: {
        value: 'necessary',
        enabled: true,
        readonly: true, // cookie categories with readonly=true are all treated as "necessary cookies"
      },
      cookie_table: [
        // list of all expected cookies
        {
          col1: '_pinpoint_session',
          col2: 'pinpointhq.com',
          col3: translations.as_soon_as_browser_window_is_closed,
          col4: translations.allows_us_to_associate_file_uploads,
        },
        {
          col1: 'LSW_WEB',
          col2: window.location.hostname,
          col3: translations.as_soon_as_browser_window_is_closed,
          col4: translations.allows_our_load_balancer,
        },
        {
          col1: 'LSW_WEB',
          col2: 'app.pinpointhq.com',
          col3: translations.as_soon_as_browser_window_is_closed,
          col4: translations.allows_our_load_balancer,
        },
        {
          col1: 'cc_cookie',
          col2: window.location.hostname,
          col3: translations.six_months,
          col4: translations.saves_your_decisions,
        },
      ],
    });

    blocks.push({
      title: translations.analytics_and_performance_cookies,
      description: translations.these_cookies_remember_the_choices,
      toggle: {
        value: 'analytics', // your cookie category
        enabled: false,
        readonly: false,
      },
      cookie_table: [
        // list of all expected cookies
        {
          col1: 'ahoy_visitor',
          col2: 'pinpointhq.com',
          col3: translations.two_years,
          col4: translations.internal_metrics_collector,
        },
        {
          col1: 'ahoy_visit',
          col2: 'pinpointhq.com',
          col3: translations.four_hours,
          col4: translations.internal_metrics_collector,
        },
      ],
    });

    if (customContent.further_information) {
      blocks.push({
        title: translations.more_information,
        description: customContent.further_information,
      });
    }

    const cookieConsentSettings: CookieConsentConfig = {
      delay: 1,
      autorun: true,
      current_lang: 'en', // always 'en' because we are passing our strings from the view
      autoclear_cookies: true,
      page_scripts: true,
      auto_language: null,
      onFirstAction(userPreferences, cookie) {},
      onAccept: () => {
        if (cookieConsent.allowedCategory('analytics')) {
          const event = new Event('cookieconsent-analytics');
          document.dispatchEvent(event);
        }
      },
      onChange(cookie, changedPreferences) {},

      languages: {
        en: {
          consent_modal: {
            title: translations.cookies,
            description: customContent.notice,
            primary_btn: {
              text: translations.accept_all,
              role: 'accept_all',
            },
            secondary_btn: {
              text: translations.settings,
              role: 'settings',
            },
          },
          settings_modal: {
            title: translations.cookie_preferences,
            save_settings_btn: translations.save_settings,
            accept_all_btn: translations.accept_all,
            reject_all_btn: translations.reject_all,
            close_btn_label: translations.close,
            cookie_table_headers: [
              { col1: translations.name },
              { col2: translations.domain },
              { col3: translations.expiration },
              { col4: translations.description },
            ],
            blocks,
          },
        },
      },
    };

    cookieConsent.run(cookieConsentSettings);
  }

  setTimeout(() => {
    // wipe the cookies on every browse if we have saved any decision
    const cookieConsentCookieName = cookieConsent.getConfig('cookie_name');
    const cookieValue = cookieConsent.validCookie(cookieConsentCookieName);
    if (cookieValue) {
      cookieConsent.autoclearCookies(true);
    }
  }, 1000);
}

function hideCookieNotice() {
  // allows us to override the test env check and show the cookie notice.
  if (window?.location?.search === '?show_cookies_notice=true') return false;

  // don't show the notice in test env
  if ((window as any)?.railsEnv === 'test') return true;

  // don't show the notice on login endpoints
  const excludedPathNames = ['login', 'admin', 'platform', 'extensions'];
  const pathName = window.location.pathname;
  if (excludedPathNames.includes(pathName.split('/')[1])) return true;

  return false;
}

document.addEventListener('turbolinks:load', () => {
  if (hideCookieNotice()) return;

  // we need a new cookieconsent every time turbolinks destroys our old one.
  createNewCookieConsent();
});
